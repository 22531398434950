import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  inject,
  ViewChildren,
  ElementRef,
  QueryList,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { User } from 'src/app/models/User';
import { selectProfil } from '../../users/+profil/profil.selectors';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { Item } from 'src/app/models/Item';

@Component({
  selector: 'app-dialog-add-cabinet',
  templateUrl: './dialog-add-cabinet.component.html',
  styleUrls: ['./dialog-add-cabinet.component.css'],
})
export class DialogAddCabinetComponent implements OnInit, OnChanges {
  @Output() basic = new EventEmitter<boolean>();
  @Output() closeDialog = new EventEmitter<Cabinet>();
  template: Template | null = AssessmentStoreService.template;

  @ViewChildren('input') inputs = QueryList<ElementRef>;
  cabinet$ = new Observable<Cabinet>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  user = null as unknown as User;
  templateItems: Item[] = [] as Item[];

  private formBuilder = inject(FormBuilder);

  formDynamisch = this.formBuilder.group({});

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['template']) {
      console.log('template$ changed:', this.template);
      if (this.template) {
        // console.log('ngOnChanges() template:', this.template);
        this.templateItems = [];
        if (this.template && this.template.Anlage) {
          this.templateItems = this.template.Anlage.sort(
            (a, b) => a.ItemIndex - b.ItemIndex,
          );
        }
        console.log('sorted Items count:', this.templateItems.length);
        this.template.Anlage?.map((anlage) => {
          if (!this.formDynamisch.get(anlage._id)) {
            this.formDynamisch.addControl(
              anlage._id || '',
              this.formBuilder.control(''),
            );
            // console.log('added Control:', anlage._id);
          }
        });
      }
      // console.log('ngOnChanges() this.templateItems:', this.templateItems);
      this.ngOnInit();
    }
  }

  ngOnInit() {
    const profil = this.store.select(selectProfil);
    profil.subscribe((usr) => {
      this.user = usr;
    });

    if (this.template) {
      this.templateItems = [];
      if (this.template && this.template.Anlage) {
        this.templateItems = this.template.Anlage.sort(
          (a, b) => a.ItemIndex - b.ItemIndex,
        );
      }
      console.log('sorted Items count:', this.templateItems.length);
      this.template.Anlage?.map((anlage) => {
        if (!this.formDynamisch.get(anlage._id)) {
          this.formDynamisch.addControl(
            anlage._id || '',
            this.formBuilder.control(''),
          );
        }
      });
    }
  }

  getTemplateAnlageItems(): Item[] {
    if (this.template && this.template.Anlage) {
      const sortItems = [] as Item[];
      this.template.Anlage?.map((a) => sortItems.push(a));
      return sortItems.sort((a, b) => a.ItemIndex - b.ItemIndex);
    }
    return [] as Item[];
  }

  preventEnter(event: any, itm: Item) {
    if (event.key == 'Enter') {
      let nxtId: string | null | undefined = null;
      let elementToFocus: any | null = null;
      nxtId = this.template?.Anlage?.find(
        (i) => i.ItemIndex == itm.ItemIndex + 1,
      )?._id;
      nxtId = nxtId
        ? nxtId
        : this.template?.Anlage?.find((i) => i.ItemIndex == 0)?._id;
      if (nxtId) {
        elementToFocus = (<any>this.inputs)?.find(
          (inElement: any) => inElement.nativeElement.id == nxtId,
        );
      }
      if (elementToFocus) elementToFocus.nativeElement.focus();
      event.preventDefault();
      return false;
    }
    return true;
  }

  onSubmitAnlage() {
    const anlage = this.formDynamisch.getRawValue() as {
      _id: string;
      Template: Template;
      Anlage: [{ ItemIndex: number; Value: object }];
    };

    const anlageArrayObject = Object.entries(anlage).map(
      ([Title, Value], index) =>
        ({
          ItemId: this.template?.Anlage.find((x) => x.ItemIndex == index)?._id,
          Value: Value,
        }) as { ItemId: string; Value: object },
    );

    const cabinet: Cabinet = {
      _id: anlage._id,
      Uid: this.user.Uid,
      Anlage: anlageArrayObject,
    };

    this.service.postCabinet(cabinet).subscribe((newOne) => {
      console.log('DIALOG: new One created', newOne);
      this.closeDialog.emit(newOne);
    });
  }

  openChange(value: boolean) {
    this.basic.emit(false);
  }
}
