import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ClarityModule } from '@clr/angular';
import { ClrAddonsModule } from '@porscheinformatik/clr-addons';
import { Observable } from 'rxjs';
import { MqttMessage } from 'src/app/models/MqttMessage';
import { Telecontrol } from 'src/app/models/Telecontrol';
import { SocketMqttMessagesService } from 'src/app/services/socket-mqtt-messages.service';

@Component({
  standalone: true,
  imports: [CommonModule, ClarityModule, ClrAddonsModule],
  selector: 'app-telecontrols-messages-grid',
  templateUrl: './telecontrols-messages-grid.component.html',
  styleUrls: ['./telecontrols-messages-grid.component.css'],
})
export class TelecontrolsMessagesGridComponent implements OnInit {
  @Input() telecontrol = {} as Telecontrol;

  messages$ = new Observable<MqttMessage>();

  constructor(private socketMqttMessagesService: SocketMqttMessagesService) {}

  ngOnInit() {
    this.messages$ = this.socketMqttMessagesService.getMessageObservable(
      this.telecontrol.MQTT.Identifier,
    );
  }
}
