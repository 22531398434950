import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { StatusService } from '../services/status.service';
import { AuthService } from '../services/auth.service';
import { SocketImportService } from '../services/socket-import.service';

import {
  selectProfil,
  selectProfilError,
  selectProfilLoading,
  selectProfilRoles,
} from '../views/users/+profil/profil.selectors';
import { ProfilActions } from '../views/users/+profil/profil.actions';

import {
  selectTelecontrols,
  selectTelecontrolsError,
  selectTelecontrolsLoading,
} from '../views/telecontrols/+store/telecontrol.selectors';
import { TelecontrolActions } from '../views/telecontrols/+store/telecontrol.actions';

import { LayoutService } from '../shared/layout.service';
import { AssessmentStoreService } from '../services/assessment.service';
import { AssessmentSyncService } from '../services/assesmentsync.service';
import { SocketMqttMessagesService } from '../services/socket-mqtt-messages.service';
import { SocketMqttPresetsService } from '../services/socket-mqtt-presets.service';
import { MqttMessage } from '../models/MqttMessage';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  clrVerticalNavCollapsed = false;

  fernwirktechnik = false;
  info = { Timestamp: '', Messages: [] } as MqttMessage;
  warn = { Timestamp: '', Messages: [] } as MqttMessage;
  alarm = { Timestamp: '', Messages: [] } as MqttMessage;
  update = false;

  // FIXME: loading$ und error$ - werden diese angezeigt?
  profil$ = this.store.select(selectProfil);
  loading$ = this.store.select(selectProfilLoading);
  error$ = this.store.select(selectProfilError);

  // FIXME: loading$ und error$ - werden diese angezeigt?
  telecontrols$ = this.store.select(selectTelecontrols);
  loadingTelecontrols$ = this.store.select(selectTelecontrolsLoading);
  errorTelecontrols$ = this.store.select(selectTelecontrolsError);

  isManager$ = this.store.select(selectProfilRoles(['manager']));
  isService$ = this.store.select(selectProfilRoles(['service']));
  risksOnline$ = this.risksService.isOnline$;
  risksInSync$ = this.risksService.isInSync$;

  constructor(
    private store: Store,
    private authService: AuthService,
    private socketImportService: SocketImportService,
    private socketMqttMessagesService: SocketMqttMessagesService,
    private socketMqttPresetsService: SocketMqttPresetsService,
    private layoutService: LayoutService,
    private statusService: StatusService,
    private risksService: AssessmentStoreService,
    private risksSyncService: AssessmentSyncService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(ProfilActions.loadProfil());

    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        this.socketImportService.connectClientSession(profil.Uid);
      }
    });

    // this.profil$.subscribe((profil) => {
    //   if (profil?.Features?.Risks && this.risksService.isOnline$.value) {
    //     if (this.risksService.offlineUpdates()) {
    //       this.risksService.checkForOfflineData();
    //     }
    //   }
    // });

    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        this.store.dispatch(TelecontrolActions.loadTelecontrols());

        this.telecontrols$.subscribe((telecontrols) => {
          this.fernwirktechnik = false;

          if (telecontrols.length > 0) {
            this.fernwirktechnik = true;
          }

          telecontrols.forEach((telecontrol) => {
            this.socketMqttMessagesService.connectClientSession(
              telecontrol.MQTT.Identifier,
            );

            this.socketMqttPresetsService.connectClientSession(
              telecontrol.MQTT.Identifier,
            );
          });
        });
      }
    });

    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        this.store.dispatch(TelecontrolActions.loadTelecontrols());

        this.socketMqttMessagesService.alarm.subscribe((value) => {
          this.alarm = value;
        });

        this.socketMqttMessagesService.warn.subscribe((value) => {
          this.warn = value;
        });

        this.socketMqttMessagesService.info.subscribe((value) => {
          this.info = value;
        });
      }
    });

    this.profil$.subscribe((profil) => {
      if (profil?.Features?.Risks && this.risksService.isOnline$.value) {
        if (this.risksSyncService.offlineUpdates()) {
          this.risksSyncService.startSynchronisation();
        }
      }
    });

    this.statusService.currentStatus.subscribe((status: boolean) => {
      this.update = status;
    });
  }

  onLogout() {
    this.authService.logout();
    this.socketImportService.closeClientSession();
    this.socketMqttMessagesService.closeClientSession();
    this.socketMqttPresetsService.closeClientSession();
    this.risksService.clearOfflineData();

    localStorage.clear();
  }

  onUpdate() {
    document.location.reload();
  }

  checkRiskSync() {
    if (this.risksSyncService.offlineUpdates())
      this.risksSyncService.startSynchronisation();
  }

  readThemeMode() {
    return this.layoutService.readThemeMode();
  }

  public onToggleTheme() {
    this.layoutService.toggleMode();
  }
}
