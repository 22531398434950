<div class="card">
  <h6 class="card-header">
    <cds-icon shape="users" size="md"></cds-icon>
    Benutzer
    <span style="float: right">
      <cds-icon shape="view-list" size="md"></cds-icon>
    </span>
  </h6>

  <div class="card-block">
    <h4 class="card-title" *ngIf="(error$ | async)?.status">
      <div
        *ngIf="error$ | async as error"
        class="alert alert-danger"
        role="alert"
      >
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>

    <div class="card-text">
      <button
        class="btn btn-outline"
        (click)="onEdit()"
        [disabled]="!selected || !selected._id"
      >
        <cds-icon shape="dot-circle"></cds-icon> bearbeiten
      </button>
      &nbsp;
      <button class="btn btn-primary" (click)="onInvite()">
        <cds-icon shape="plus-circle"></cds-icon> hinzufügen
      </button>
      &nbsp;
      <button
        class="btn btn-danger"
        (click)="onDelete()"
        [disabled]="!selected || !selected._id"
      >
        <cds-icon shape="minus-circle"></cds-icon> entfernen
      </button>

      <!-- (clrDgSingleSelectedChange)="selectionChanged($event)" -->
      <ng-container *ngIf="users$ | async as users">
        <clr-datagrid
          [clrDgLoading]="(loading$ | async) === true"
          [clrDgRowSelection]="true"
          [(clrDgSingleSelected)]="selected"
          [clrUseLocalStoreOnly]="true"
          [clrStatePersistenceKey]="{
            key: 'statePersistence.users.datagrid.list',
            serverDriven: false,
          }"
          [clrPaginationDescription]="
            '{{first}} - {{last}} von {{total}} Einträgen'
          "
        >
          <clr-dg-placeholder>
            Wir konnten keine Einträge finden!
          </clr-dg-placeholder>

          <clr-dg-column [clrDgField]="'Username'">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
              <cds-icon shape="tag"></cds-icon>&nbsp;Username
            </ng-container>
          </clr-dg-column>

          <clr-dg-column [clrDgField]="'Firstname'">
            <ng-container *clrDgHideableColumn="{ hidden: hideFirstname }">
              <cds-icon shape="tag"></cds-icon>&nbsp;Vorname
            </ng-container>
          </clr-dg-column>

          <clr-dg-column [clrDgField]="'Lastname'">
            <ng-container *clrDgHideableColumn="{ hidden: hideLastname }">
              <cds-icon shape="tag"></cds-icon>&nbsp;Nachname
            </ng-container>
          </clr-dg-column>

          <clr-dg-column [clrDgField]="'Uid'">
            <ng-container *clrDgHideableColumn="{ hidden: hideUid }">
              <cds-icon shape="tag"></cds-icon>&nbsp;Uid
            </ng-container>
          </clr-dg-column>

          <clr-dg-column [clrDgField]="'SignIn.IsBlocked'">
            <ng-container *clrDgHideableColumn="{ hidden: hideStatus }">
              <cds-icon shape="tag"></cds-icon>&nbsp;Status
            </ng-container>
            <clr-dg-filter [clrDgFilter]="isBlockedFilter">
              <clr-checkbox-wrapper>
                <input
                  type="checkbox"
                  clrCheckbox
                  value="true"
                  name="options"
                  id="locked"
                  (change)="toggleBlocked($event)"
                />
                <label for="locked"
                  ><cds-icon shape="lock"></cds-icon> gesperrt</label
                >
              </clr-checkbox-wrapper>
              <clr-checkbox-wrapper>
                <input
                  type="checkbox"
                  clrCheckbox
                  value="false"
                  name="options"
                  id="unlocked"
                  (change)="toggleBlocked($event)"
                />
                <label for="unlocked"
                  ><cds-icon shape="unlock"></cds-icon> freigegben</label
                >
              </clr-checkbox-wrapper>
            </clr-dg-filter>
          </clr-dg-column>

          <clr-dg-row
            *clrDgItems="let user of users"
            [clrDgItem]="user"
            [clrDgSelectable]="true"
          >
            <clr-dg-cell>
              <!-- <a [routerLink]="['/users', user._id]">{{ user.Username }}</a> -->
              {{ user.Username }}
            </clr-dg-cell>
            <clr-dg-cell>
              {{ user.Firstname }}
            </clr-dg-cell>
            <clr-dg-cell>
              {{ user.Lastname }}
            </clr-dg-cell>
            <clr-dg-cell>
              {{ user.Uid }}
            </clr-dg-cell>
            <clr-dg-cell>
              <ng-container *ngIf="user.SignIn?.IsBlocked">
                <cds-icon shape="lock"></cds-icon>
              </ng-container>
              <ng-container *ngIf="!user.SignIn?.IsBlocked">
                <cds-icon shape="unlock"></cds-icon>
              </ng-container>
            </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
                >Einträge pro Seite</clr-dg-page-size
              >
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="invite">
  <app-users-form-item-invite
    (invited)="onInvited($event)"
  ></app-users-form-item-invite>
</ng-container>

<ng-container *ngIf="delete">
  <app-users-form-item-delete
    *ngIf="selected"
    [user]="selected"
    (deleted)="onDeleted($event)"
  ></app-users-form-item-delete>
</ng-container>

<ng-container *ngIf="detail">
  <app-users-form-item-edit
    *ngIf="selected"
    [selected]="selected"
    (edited)="onEdited($event)"
  ></app-users-form-item-edit>
</ng-container>
