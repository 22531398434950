<ng-container *ngIf="messages$ | async as messages">
  <clr-datagrid
    [clrUseLocalStoreOnly]="true"
    [clrStatePersistenceKey]="{
      key: 'statePersistence.telecontrol.datagrid.messages.' + telecontrol.Uid,
      serverDriven: false,
    }"
    [clrPaginationDescription]="'{{first}} - {{last}} von {{total}} Einträgen'"
  >
    <clr-dg-placeholder>Wir konnten keine Einträge finden!</clr-dg-placeholder>
    <clr-dg-column [clrDgField]="'Id'">
      <ng-container *clrDgHideableColumn="{ hidden: true }">
        <cds-icon shape="hashtag"></cds-icon>&nbsp;Id
      </ng-container>
    </clr-dg-column>
    <!-- <clr-dg-column [clrDgField]="'Class'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Art
      </ng-container>
      <clr-dg-filter>
        <clr-enum-filter clrProperty="Class"> </clr-enum-filter>
      </clr-dg-filter>
    </clr-dg-column> -->
    <clr-dg-column
      [clrDgField]="
        messages.Messages && messages.Messages.length > 0 ? 'Class' : ''
      "
    >
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Art
      </ng-container>
      <clr-dg-filter *ngIf="messages.Messages && messages.Messages.length > 0">
        <clr-enum-filter clrProperty="Class"></clr-enum-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'Timestamp'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Zeit
      </ng-container>
      <clr-dg-filter>
        <clr-date-filter
          clrProperty="Timestamp"
          [timeActive]="true"
        ></clr-date-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'Message'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Meldung
      </ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'System'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        System
      </ng-container>
      <clr-dg-filter>
        <clr-enum-filter clrProperty="System"> </clr-enum-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'Location'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Ort
      </ng-container>
      <clr-dg-filter>
        <clr-enum-filter clrProperty="Location"> </clr-enum-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'Device'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Gerät
      </ng-container>
      <clr-dg-filter>
        <clr-enum-filter clrProperty="Device"> </clr-enum-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-row
      *clrDgItems="let message of messages.Messages"
      [clrDgItem]="message"
      [ngClass]="{
        'datagrid-highlight-error': message.Class.toLowerCase() === 'alarm',
        'highlight-text-error': message.Class.toLowerCase() === 'alarm',
        'datagrid-highlight-warning': message.Class.toLowerCase() === 'warnung',
        'highlight-text-warning': message.Class.toLowerCase() === 'warnung',
        'datagrid-highlight-info': message.Class.toLowerCase() === 'info',
        'highlight-text-info': message.Class.toLowerCase() === 'info',
      }"
    >
      <clr-dg-cell>{{ message.Id }}</clr-dg-cell>

      <clr-dg-cell style="display: flex; align-items: center">
        <cds-icon
          *ngIf="message.Class.toLowerCase() === 'alarm'"
          shape="error-standard"
          size="sm"
        ></cds-icon>
        <cds-icon
          *ngIf="message.Class.toLowerCase() === 'warnung'"
          shape="warning-standard"
          size="sm"
        ></cds-icon>
        <cds-icon
          *ngIf="message.Class.toLowerCase() === 'info'"
          shape="info-standard"
          size="sm"
        ></cds-icon>
        &nbsp;{{ message.Class }}
      </clr-dg-cell>

      <clr-dg-cell>{{
        message.Timestamp | date: "dd.MM.YYYY HH:mm:ss"
      }}</clr-dg-cell>
      <clr-dg-cell>{{ message.Message }}</clr-dg-cell>
      <clr-dg-cell>{{ message.System }}</clr-dg-cell>
      <clr-dg-cell>{{ message.Location }}</clr-dg-cell>
      <clr-dg-cell>{{ message.Device }}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination [clrDgPageSize]="10">
        <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
          >Einträge pro Seite</clr-dg-page-size
        >
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
  <p></p>
</ng-container>
