import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';
import { TelecontrolsMessagesGridComponent } from '../telecontrols-messages-grid/telecontrols-messages-grid.component';
import { Telecontrol } from 'src/app/models/Telecontrol';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    ClarityModule,
    TelecontrolsMessagesGridComponent,
  ],
  selector: 'app-telecontrols-messages-grid-card',
  templateUrl: './telecontrols-messages-grid-card.component.html',
  styleUrls: ['./telecontrols-messages-grid-card.component.css'],
})
export class TelecontrolsMessagesGridCardComponent {
  @Input() telecontrol = {} as Telecontrol;
  @Input() extern = false;
}
