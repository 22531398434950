<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedTelecontrols$ | async as combinedTelecontrols">
      <div *ngFor="let telecontrol of combinedTelecontrols">
        <app-telecontrols-messages-grid-card
          [telecontrol]="telecontrol"
        ></app-telecontrols-messages-grid-card>
      </div>
    </div>
  </div>
</div>
