import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Evaluation } from 'src/app/models/Evaluation';
import { BaseImage } from 'src/app/models/Image';
import { ClrLoadingState } from '@clr/angular';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import {
  CheckObj,
  DialogAddImageComponent,
} from '../dialog-add-image/dialog-add-image.component';
import { event } from '@cds/core/internal';
import { Template } from 'src/app/models/Template';
import { Observable, Subject } from 'rxjs';

interface categoryItm {
  Category: string;
  Gefaehrdung: string;
  Nr: number;
}
interface categories /*extends Comparator<categories>*/ {
  Category: string;
  Items: categoryItm[];
}

@Component({
  selector: 'app-risks-tab-images-evaluation',
  templateUrl: './risks-tab-images-evaluation.component.html',
  styleUrls: ['./risks-tab-images-evaluation.component.css'],
})
export class RiskTabEvaluationImagesComponent implements OnInit {
  @Input() evaluation: Evaluation | null = null;
  @Input() disabled = false;
  @Input() maxImageCount = 5;
  @Output() updated = new EventEmitter();
  @Output() init = new EventEmitter();
  online = this.service.isOnline$;
  filesDialog: DialogAddImageComponent | null = null;
  template: Template | null = AssessmentStoreService.template;
  infoTxt = '';
  addBtnDisabled = false;
  dialogAddFile = false;
  title = '';
  message = '';
  imgViewSrc = '';
  dialogViewImg = false;
  dialogViewOptions = false;
  categories: categories[] = [];
  imgIndex: number = 0;

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private service: AssessmentStoreService) {}

  ngOnInit() {
    this.template = AssessmentStoreService.template;
    if (this.template) {
      this.categories = [];
      this.template.Category?.forEach((c) => {
        const foundCat = this.categories.find(
          (cat) => cat.Category === c.Category,
        );
        if (foundCat) {
          if (!foundCat.Items.includes(c)) foundCat.Items.push(c);
        } else {
          this.categories.push({ Category: c.Category, Items: [c] });
        }
      });
    }
    this.init.emit(this);
  }

  setDialog($event: any) {
    this.filesDialog = $event as DialogAddImageComponent;
    console.log('Dialog set:', this.filesDialog);
    this.isMaxImageCount();
  }

  getDialogImgCount(): number {
    if (this.evaluation?.Images) {
      let count = this.maxImageCount - this.evaluation.Images.length;
      count = count < 0 ? 0 : count;
      return count;
    }
    return this.maxImageCount;
  }

  isMaxImageCount(): boolean {
    if (
      this.evaluation?.Images &&
      this.evaluation?.Images?.length < this.maxImageCount
    ) {
      this.addBtnDisabled = false;
      this.infoTxt = '';
    } else {
      this.addBtnDisabled = true;
      this.infoTxt = `\tMaximal ${this.maxImageCount} Bilder möglich.`;
      return true;
    }
    return false;
  }

  onDialogAddFileClosing() {
    this.dialogAddFile = false;
  }

  onAddFileClick() {
    if (this.disabled) return false;
    this.dialogAddFile = !this.isMaxImageCount();
    return false;
  }

  getThumbImage(image: BaseImage) {
    const str64 = String.fromCharCode.apply(null, image.Thumb.data);
    return `data:${image.MIME};base64,${btoa(str64)}`;
  }

  showImage(image: BaseImage) {
    const idx = this.evaluation?.Images?.indexOf(image);
    this.imgIndex = idx ? idx : 0;
    this.dialogViewImg = true;
  }

  setCategory(img: BaseImage, element: categoryItm) {
    if (img.LinkedNumber?.includes(element.Nr)) {
      img.LinkedNumber.splice(img.LinkedNumber.indexOf(element.Nr), 1);
    } else {
      if (img.LinkedNumber) img.LinkedNumber.push(element.Nr);
      else img.LinkedNumber = [element.Nr];
    }
  }

  getCategories(img: BaseImage): string {
    let result = '';
    let count = 0;
    this.categories.forEach((category) => {
      category.Items?.forEach((catItem) => {
        if (img.LinkedNumber?.includes(catItem.Nr)) {
          result += result.length == 0 ? '' : ', ';
          result += catItem.Gefaehrdung;
          count += 1;
        }
      });
    });
    if (count == 0) {
      result = ' --- (keine Auswahl) ---';
    } else {
      for (let i = result.length - 1; i < 18; i++) result += '.';
      result = `${result.substring(0, 17)}... (${count})`;
    }
    return result;
  }

  isCategorySet(img: BaseImage, element: categoryItm): boolean {
    if (img.LinkedNumber?.includes(element.Nr)) return true;
    return false;
  }

  onSubmit(event: any) {
    if (this.evaluation) {
      this.service
        .patchEvaluationImages(this.evaluation)
        .subscribe((result) => {
          console.log('onSubmit() => updated Evaluation:', result);
          this.evaluation = result as Evaluation;
          this.updated.emit(this.evaluation);
        });
    }
  }

  uploadFile(files: CheckObj[], index: number) {
    console.log(`updateFile() file[${index}] of `, files.length);
    if (index >= 0 && index < files.length) {
      const fileData: CheckObj = files[index];
      console.log('onUpdateFile()', fileData);

      if (fileData.file && this.evaluation) {
        this.service
          .postEvaluationImage(fileData, this.evaluation)
          .subscribe((result) => {
            console.log('updateFile() => got result', result);
            this.evaluation = result as Evaluation;
            this.updated.emit(this.evaluation);
            this.ngOnInit();
            this.filesDialog?.fileSend(true, fileData.idx);
            this.uploadFile(files, index + 1);
          });
      }
    }
  }

  onUpdateFiles(fileData: any[]) {
    console.log('onUpdateFile()', fileData);
    this.uploadFile(fileData, 0);
  }

  onDialogClosing(open: boolean) {
    console.log('DIALOG WILL BE CLOSED! value from dialog:', open);
    this.dialogViewImg = false;
  }

  getImages(): BaseImage[] {
    return this.evaluation?.Images ? this.evaluation.Images : [];
  }

  onDeleteFile(control: any) {
    this.submitTabState = ClrLoadingState.LOADING;
    if (this.evaluation) {
      console.log(`onDeleteFile control?._id : ${control?._id}`);
      this.service
        .deleteEvaluationImage(this.evaluation._id, control?._id)
        .subscribe((e) => {
          this.evaluation = e as Evaluation;
          this.updated.emit(e as Evaluation);
          this.submitTabState = ClrLoadingState.SUCCESS;
        });
    } else {
      this.submitTabState = ClrLoadingState.SUCCESS;
    }
  }
}
