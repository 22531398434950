import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { ClrAddonsModule } from '@porscheinformatik/clr-addons';
import { StatusService } from 'src/app/services/status.service';

@Component({
  standalone: true,
  imports: [CommonModule, ClarityModule, ClrAddonsModule],
  selector: 'app-p404',
  templateUrl: './p404.component.html',
  styleUrls: ['./p404.component.css'],
})
export class P404Component implements OnInit {
  currentYear = new Date().getFullYear();

  private _background = [
    'Hintergrund_Microsoft_Teams_v1.jpg',
    'Hintergrund_Microsoft_Teams_v2.jpg',
    'Hintergrund_Microsoft_Teams_v3.jpg',
    'Hintergrund_Microsoft_Teams_v4.jpg',
    'Hintergrund_Microsoft_Teams_v5.jpg',
  ];
  public background = this._background[0];

  update = false;

  constructor(
    private router: Router,
    private statusService: StatusService,
  ) {
    const min = 0;
    const max = this._background.length - 1;

    this.background =
      this._background[Math.floor(Math.random() * (max - min + 1)) + min];
  }

  ngOnInit(): void {
    this.statusService.currentStatus.subscribe((status: boolean) => {
      this.update = status;
    });
  }

  onSubmit() {
    this.router.navigate(['/']);
  }

  onUpdate() {
    document.location.reload();
  }
}
