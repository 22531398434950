import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { ClrAddonsModule } from '@porscheinformatik/clr-addons';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTelecontrol from './+store/telecontrol.reducer';
import { TelecontrolEffects } from './+store/telecontrol.effects';

import { TelecontrolsRoutingModule } from './telecontrols-routing.module';
import { TelecontrolsMessagesComponent } from './telecontrols-messages/telecontrols-messages.component';
import { TelecontrolsMessagesGridCardComponent } from './telecontrols-messages-grid-card/telecontrols-messages-grid-card.component';
import { TelecontrolsMessagesGridComponent } from './telecontrols-messages-grid/telecontrols-messages-grid.component';

// Standalon Components
// import { TelecontrolsPresetsGridCardComponent } from './telecontrols-presets-grid-card/telecontrols-presets-grid-card.component';
// import { TelecontrolsPresetsGridComponent } from './telecontrols-presets-grid/telecontrols-presets-grid.component';

@NgModule({
  declarations: [
    // TelecontrolsMessagesComponent,
    // TelecontrolsMessagesGridCardComponent,
    // TelecontrolsMessagesGridComponent,
    // TelecontrolsPresetsGridCardComponent,
    // TelecontrolsPresetsGridComponent,
  ],
  imports: [
    TelecontrolsRoutingModule,
    CommonModule,
    ClarityModule,
    ClrAddonsModule,
    StoreModule.forFeature(
      fromTelecontrol.telecontrolFeatureKey,
      fromTelecontrol.reducer,
    ),
    EffectsModule.forFeature([TelecontrolEffects]),
  ],
})
export class TelecontrolsModule {}
