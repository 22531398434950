<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h6 class="card-header">
        <cds-icon shape="form" size="md"></cds-icon>
        Feedback
        <!-- <span style="float: right">
          <cds-icon shape="form" size="md"></cds-icon>
        </span> -->
      </h6>

      <div class="card-block">
        <h4 class="card-title" *ngIf="error.error">
          <clr-alert
            [clrAlertType]="'danger'"
            (clrAlertClosedChange)="onAlertClose()"
          >
            <clr-alert-item>
              <span class="alert-text"
                >{{ error.error.name }} | {{ error.error.message }}</span
              >
            </clr-alert-item>
          </clr-alert>
        </h4>
        <div class="card-text">
          <form
            clrForm
            clrLayout="horizontal"
            [formGroup]="formFeedback"
            (ngSubmit)="onSubmit()"
          >
            <clr-input-container>
              <label
                class="clr-col-12 clr-col-md-2 clr-required-mark"
                for="subject"
                >Betreff</label
              >
              <input
                class="clr-col-12 clr-col-md-8"
                style="width: 100%"
                clrInput
                required
                id="subject"
                type="text"
                formControlName="Subject"
              />
              <!-- <clr-control-helper>Betreff</clr-control-helper> -->
              <clr-control-error
                >Betreff-Feld muss ausgefüllt werden</clr-control-error
              >
            </clr-input-container>
            <clr-textarea-container>
              <label
                class="clr-col-12 clr-col-md-2 clr-required-mark"
                for="message"
                >Nachricht</label
              >
              <textarea
                class="clr-col-12 clr-col-md-8"
                style="width: 100%"
                clrTextarea
                id="message"
                formControlName="Message"
                required
              ></textarea>
              <!-- <clr-control-helper>Nachricht</clr-control-helper> -->
              <clr-control-error
                >Nachricht-Feld muss ausgefüllt werden.</clr-control-error
              >
            </clr-textarea-container>

            <p></p>

            <button
              class="btn btn-primary"
              type="submit"
              [clrLoading]="submitBtnState"
              [disabled]="formFeedback.invalid"
            >
              senden
            </button>
          </form>
        </div>
      </div>

      <!-- <div class="card-footer"></div> -->
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="success" [clrModalClosable]="false">
  <h3 class="modal-title">
    <cds-icon shape="success-standard" size="md"></cds-icon>
    Erfolgreich versendet!
  </h3>
  <div class="modal-body">
    <p>
      Dein Feedback wurde erfolgreich versendet.
      <br /><b>Vielen Dank für deine Rückmeldung!</b>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onModalClose()">
      Ok
    </button>
  </div>
</clr-modal>
