<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <h6 class="card-header">
    <cds-icon shape="host-group" size="md"></cds-icon>
    Schaltanlagen
    <span style="float: right">
      <cds-icon shape="view-list" size="md"></cds-icon>
    </span>
  </h6>

  <div *ngIf="refresh(risksOnline$.value, !risksInSync$.value)"></div>

  <div class="card-text">
    <br />
    <button
      *ngIf="isService"
      class="btn btn-outline"
      style="float: right"
      (click)="onBtnEditTemplateClick()"
    >
      Vorlage bearbeiten
    </button>
    <div class="btn-group btn-primary">
      <span style="margin-left: 10px; float: left" class="card-title">
        <button class="btn btn-outline" (click)="onBtnAddClick()">
          <cds-icon shape="plus-circle"></cds-icon> hinzufügen
        </button>
        <button
          *ngIf="risksOnline$.value && (isManager || isService)"
          class="btn btn-outline"
          (click)="onBtnImportClick()"
        >
          <cds-icon shape="plus-circle"></cds-icon> importieren
        </button>
        <button
          class="btn btn-outline"
          (click)="onBtnEditClick()"
          [disabled]="selected.length !== 1"
        >
          <cds-icon shape="eraser"></cds-icon> bearbeiten
        </button>
        <button
          *ngIf="isManager || isService"
          class="btn btn-outline"
          (click)="onDeleteSelected()"
          [disabled]="selected.length === 0"
        >
          <cds-icon shape="minus-circle"></cds-icon> entfernen
        </button>
      </span>
    </div>
    <div class="card-block">
      <ng-container *ngIf="template">
        <clr-datagrid
          #gridchild
          [(clrDgSelected)]="selected"
          [clrDgLoading]="(isLoading() | async) === true"
        >
          <clr-dg-placeholder>
            Wir konnten keine Einträge finden!
          </clr-dg-placeholder>
          <!-- ----------column-------------- -->
          <clr-dg-column
            *ngFor="let itm of getTemplateAnlageItems()"
            [clrDgSortBy]="sortationFor(itm._id)"
          >
            <clr-dg-filter>
              <app-risk-list-filter [id]="'' + itm._id"></app-risk-list-filter>
            </clr-dg-filter>
            <ng-container *clrDgHideableColumn="{ hidden: !itm.IsPrimary }">
              {{ itm.Title }}
            </ng-container>
          </clr-dg-column>
          <!-- -------------row---------------- -->
          <clr-dg-row
            *clrDgItems="let cabinet of cabinets"
            [clrDgItem]="cabinet"
          >
            <clr-dg-cell *ngFor="let anlage of getValues(cabinet.Anlage)">
              {{ anlage["col" + anlage.ItemId] }}
            </clr-dg-cell>
          </clr-dg-row>
          <!-- ------------detail-(Anlage)----- -->
          <ng-template clrIfDetail let-detail>
            <clr-dg-detail *clrIfDetail="let cabinet">
              <h3>Anlage</h3>
              <app-risks-cabinet-table [cabinet]="cabinet" />
              <br />
              <h3>Bewertungen</h3>
              <app-risks-evaluations-overview
                #auswertungenTab
                [cabinet]="cabinet"
              ></app-risks-evaluations-overview>
            </clr-dg-detail>
          </ng-template>
          <!-- -------------footer---------------- -->
          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
                >Anlagen pro Seite</clr-dg-page-size
              >
              <!-- {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} von
              {{ pagination.totalItems }} Anlagen -->
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
        <p></p>
      </ng-container>
    </div>
  </div>
</div>
<!-- <div class="card-footer">Footer</div> -->
<!-- </div> -->

<!-- DIALOGE -->

<app-dialog-add-cabinet
  *ngIf="dialogAddOpen"
  (basic)="onDialogAddClick()"
  (closeDialog)="onCabinetAdd($event)"
/>

<app-dialog-import-cabinet
  *ngIf="dialogImportOpen"
  (basic)="onDialogImportClick()"
/>

<clr-modal [(clrModalOpen)]="dialogDeleteOpen">
  <h3 class="modal-title">
    <cds-icon shape="host" size="md"></cds-icon> Schaltanlage
  </h3>
  <div class="modal-body">
    <ul>
      <li *ngFor="let s of selected">id: {{ s._id }}</li>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="deleteSelected()">löschen</button>
  </div>
</clr-modal>
