import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { MqttMessage } from '../models/MqttMessage';
import { MqttPreset } from '../models/MqttPreset';

@Injectable({
  providedIn: 'root',
})
export class SocketMqttMessagesService {
  private socketMap: { [uid: string]: any } = {};

  private mqttSubjects: { [key: string]: BehaviorSubject<MqttMessage> } = {};

  private infoSource = new BehaviorSubject<MqttMessage>({
    Timestamp: '',
    Messages: [],
  });
  public info = this.infoSource.asObservable();

  private warnSource = new BehaviorSubject<MqttMessage>({
    Timestamp: '',
    Messages: [],
  });
  public warn = this.warnSource.asObservable();

  private alarmSource = new BehaviorSubject<MqttMessage>({
    Timestamp: '',
    Messages: [],
  });
  public alarm = this.alarmSource.asObservable();

  // Getter für das BehaviorSubject einer bestimmten UID
  public getMessageObservable(uid: string) {
    return this.mqttSubjects[uid]?.asObservable();
  }

  public connectClientSession(uid: string) {
    if (this.socketMap[uid] && this.socketMap[uid].connected) {
      return;
    }

    this.socketMap[uid] = io(`${environment.mqttMessages}/user`, {
      path: '/mqtt/client-messages-v1',
    });

    this.socketMap[uid].uid = uid;

    // Erstelle ein neues BehaviorSubject für diese UID
    this.mqttSubjects[uid] = new BehaviorSubject<MqttMessage>(
      {} as MqttMessage,
    );

    // Wird ausgelöst, wenn die Verbindung zum Server hergestellt wird.
    this.socketMap[uid].on('connect', () => {
      this.socketMap[uid].emit('uid', uid);
      console.log(`Socket: Uid [${uid}] - Status [connected]`);
    });

    // Wird ausgelöst, wenn die Verbindung zum Server getrennt wird.
    this.socketMap[uid].on('disconnect', () => {
      console.log(`Socket: Uid [${uid}] - Status [disconnected]`);
    });

    // Tritt auf, wenn ein Fehler während des Verbindungsversuchs auftritt.
    this.socketMap[uid].on('connect_error', (err: any) => {
      console.log(
        `Socket: Uid [${uid}] - Status [error] - name [${err.name}] - message [${err.message}]`,
      );
    });

    // Tritt auf, wenn die Verbindung nicht innerhalb des festgelegten Zeitlimits hergestellt werden kann.
    this.socketMap[uid].on('connect_timeout', () => {
      console.log(`Socket: Uid [${uid}] - Status [timeout]`);
    });

    // Wird ausgelöst, wenn der Client versucht, sich erneut mit dem Server zu verbinden.
    this.socketMap[uid].on('reconnect_attempt', () => {
      console.log(`Socket: Uid [${uid}] - Status [reconnecting]`);
    });

    // Wird ausgelöst, wenn ein erneuter Verbindungsversuch fehlschlägt.
    this.socketMap[uid].on('reconnect_failed', () => {
      console.log(`Socket: Uid [${uid}] - Status [failed]`);
    });

    // Wird ausgelöst, wenn ein Fehler während des Reconnect-Versuchs auftritt.
    this.socketMap[uid].on('reconnect_error', () => {
      console.log(`Socket: Uid [${uid}] - Status [error]`);
    });

    // Wird ausgelöst, wenn die Verbindung wiederhergestellt wird.
    this.socketMap[uid].on('reconnect', () => {
      console.log(`Socket: Uid [${uid}] - Status [reconnecting]`);
    });

    // Wird ausgelöst, wenn eine Nachricht vom Server empfangen wird.
    this.socketMap[uid].on('message', (message: MqttPreset) => {
      this.mqttSubjects[uid].next(message);

      this.infoSource.next({
        Timestamp: message.Timestamp,
        Messages: message.Messages.filter(
          (signal) => signal.Class.toLowerCase() === 'info',
        ),
      });

      this.warnSource.next({
        Timestamp: message.Timestamp,
        Messages: message.Messages.filter(
          (signal) => signal.Class.toLowerCase() === 'warnung',
        ),
      });

      this.alarmSource.next({
        Timestamp: message.Timestamp,
        Messages: message.Messages.filter(
          (signal) => signal.Class.toLowerCase() === 'alarm',
        ),
      });
    });
  }

  public closeClientSession = () => {
    for (const uid in this.socketMap) {
      if (this.socketMap.hasOwnProperty(uid) && this.socketMap[uid] != null) {
        this.socketMap[uid].disconnect();
        this.socketMap[uid].close();
        console.log(`Socket: Uid [${uid}] - Status: [closed]`);
      }
    }
  };
}
