<clr-datagrid
  [clrDgLoading]="loading"
  [clrUseLocalStoreOnly]="true"
  [clrStatePersistenceKey]="{
    key: 'statePersistence.factories.datagrid',
    serverDriven: false,
  }"
  [clrPaginationDescription]="'{{first}} - {{last}} von {{total}} Einträgen'"
>
  <clr-dg-placeholder>Wir konnten keine Einträge finden!</clr-dg-placeholder>

  <clr-dg-column [clrDgField]="'Factory.Nr'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      <cds-icon shape="hashtag"></cds-icon>&nbsp;Nr
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'Factory.Name'">
    <ng-container *clrDgHideableColumn="{ hidden: hiddenName }">
      <cds-icon shape="tag"></cds-icon>&nbsp;Name
    </ng-container>
  </clr-dg-column>

  <clr-dg-row *clrDgItems="let item of Items" [clrDgItem]="item">
    <clr-dg-cell>
      <a [routerLink]="['/factories', item.Factory.Uid]">
        {{ item.Factory.Nr }}
      </a>
    </clr-dg-cell>
    <clr-dg-cell>
      <a [routerLink]="['/factories', item.Factory.Uid]">
        {{ item.Factory.Name }}
      </a>
    </clr-dg-cell>
  </clr-dg-row>

  <ng-container ngProjectAs="clr-dg-detail" *ngIf="extended">
    <clr-dg-detail *clrIfDetail="let item">
      <clr-dg-detail-header>
        <cds-icon shape="hashtag"></cds-icon>&nbsp;<a
          [routerLink]="['/factories', item.Factory.Uid]"
          >{{ item.Factory.Nr }}</a
        >
        |
        <cds-icon shape="tag"></cds-icon>&nbsp;<a
          [routerLink]="['/factories', item.Factory.Uid]"
          >{{ item.Factory.Name | uppercase }}</a
        >
      </clr-dg-detail-header>

      <clr-dg-detail-body>
        <div cds-text="subsection">
          <cds-icon shape="contract"></cds-icon> Verträge
        </div>
        <app-contracts-list-grid
          [extended]="false"
          [hiddenName]="false"
          [hiddenFactory]="true"
          [contracts]="item.Contracts"
        ></app-contracts-list-grid>
      </clr-dg-detail-body>
    </clr-dg-detail>
  </ng-container>

  <clr-dg-footer>
    <clr-dg-pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
        >Einträge pro Seite</clr-dg-page-size
      >
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
