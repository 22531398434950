import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { Item } from 'src/app/models/Item';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { BaseImage } from 'src/app/models/Image';

@Component({
  selector: 'app-risks-cabinet-table',
  templateUrl: './risks-cabinet-table.component.html',
  styleUrls: ['./risks-cabinet-table.component.css'],
})
export class RisksCabinetTableComponent implements OnInit, OnChanges {
  @Input() cabinet: Cabinet | null = null;
  cabinet$: Observable<Cabinet> | null = null;
  template = AssessmentStoreService.template;
  currentPreviewImageId = undefined as string | undefined;
  currentPreviewIndex: number = 0;

  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['admin']));

  isService = false;
  isManager = false;

  onHelper = false;
  onImages = false;
  imgPreviewSrc = undefined as string | undefined;
  imgIndex = 0;
  title = '';
  message = '';

  constructor(
    private service: AssessmentStoreService,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if (this.cabinet$) {
      this.cabinet$?.subscribe((cabinet) => {
        this.cabinet = cabinet;
        if (cabinet?.Images && cabinet?.Images.length > 0) {
          let found = false;
          cabinet?.Images.forEach((image) => {
            this.service.loadImage(image._id);
            if (image.AsPreview) {
              this.currentPreviewImageId = image._id;
              found = true;
            }
          });
          if (!found) {
            this.currentPreviewImageId = undefined;
          } else {
            const image = this.cabinet?.Images?.find(
              (img) =>
                this.currentPreviewImageId &&
                img._id == this.currentPreviewImageId,
            );
            if (image) {
              const str64 = String.fromCharCode.apply(null, image.Thumb.data);
              this.imgPreviewSrc = `data:${image.MIME};base64,${btoa(str64)}`;
              this.imgIndex = cabinet?.Images?.indexOf(image);
            }
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['cabinet$']) {
        this.cabinet$?.subscribe((cabinet) => {
          this.cabinet = cabinet;
        });
      }
      if (changes['cabinet']) {
        if (this.cabinet?.Images && this.cabinet?.Images.length > 0) {
          let found = false;
          this.cabinet?.Images.forEach((image) => {
            if (image.AsPreview) {
              this.currentPreviewImageId = image._id;
              found = true;
            }
          });
          if (!found) {
            this.currentPreviewImageId = undefined;
          } else {
            const image = this.cabinet?.Images?.find(
              (image) =>
                this.currentPreviewImageId &&
                image._id == this.currentPreviewImageId,
            );
            if (image) {
              const str64 = String.fromCharCode.apply(null, image.Thumb.data);
              this.imgPreviewSrc = `data:${image.MIME};base64,${btoa(str64)}`;
              this.imgIndex = this.cabinet?.Images?.indexOf(image);
            }
          }
        } else {
          this.currentPreviewImageId = undefined;
          this.imgPreviewSrc = undefined;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  getImages(): BaseImage[] {
    if (this.cabinet?.Images) return this.cabinet.Images;
    else return [];
  }

  showImages() {
    this.onImages = true;
  }

  getTemplateAnlageItems(): Item[] {
    if (this.template && this.template.Anlage) {
      const sortItems = [] as Item[];
      this.template.Anlage?.map((a) => sortItems.push(a));
      return sortItems.sort((a, b) => a.ItemIndex - b.ItemIndex);
    }
    return [] as Item[];
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';
    this.onHelper = true;
    return false;
  }

  onBtnEditCabinetClick() {
    this.router.navigate(['/risks/editecabinet', this.cabinet?._id]);
  }

  onBtnEditTemplateClick() {
    this.router.navigate(['/risks/editetemplate', this.template?._id]);
  }

  onDialogClosing(open: boolean) {
    console.log('DIALOG WILL BE CLOSED! value from dialog:', open);
    this.onImages = false;
  }

  ValueFromItem(item: object) {
    try {
      const result = this.cabinet?.Anlage?.find(
        (a) => a.ItemId == (item as Item)._id,
      )?.Value;
      if (result == undefined) return '';
      return result;
    } catch (err) {
      console.error(err);
      return '';
    }
  }
}
