// Angular Core and Module Imports
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgModule, isDevMode, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ClarityModule, ClrCommonStringsService } from '@clr/angular';
import { ClrAddonsModule } from '@porscheinformatik/clr-addons';

// Third-party Library Imports
import { CodeInputModule } from 'angular-code-input';
import { ToastrModule } from 'ngx-toastr';

// Routing Module
import { AppRoutingModule } from './app-routing.module';

// Services and Interceptors
import { AuthInterceptor } from './services/auth.interceptor';
import { RouteManagerInterceptor } from 'src/app/services/route.interceptor';
import { AssessmentOfflineModule } from './services/assessmentoffline.module';

// Module
import { InfoModule } from './views/info/info.module';

// Components
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { RequestPwdComponent } from './pages/request-pwd/request-pwd.component';
import { RenewPwdComponent } from './pages/renew-pwd/renew-pwd.component';

// Environment and Locale
import { germanLocale } from './translations/german';

// Clarity Icons
import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  // resistorIcon,
  // inductorIcon,
  // capacitorIcon,
  envelopeIcon,
  connectIcon,
  disconnectIcon,
  loginIcon,
  logoutIcon,
  lockIcon,
  infoStandardIcon,
  successStandardIcon,
  errorStandardIcon,
  gavelIcon,
  angleIcon,
} from '@cds/core/icon';

// Additional Configuration
ClarityIcons.addIcons(
  // resistorIcon,
  // inductorIcon,
  // capacitorIcon,
  envelopeIcon,
  connectIcon,
  disconnectIcon,
  loginIcon,
  logoutIcon,
  lockIcon,
  infoStandardIcon,
  successStandardIcon,
  errorStandardIcon,
  gavelIcon,
  angleIcon,
);

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    SignInComponent,
    VerifyComponent,
    RequestPwdComponent,
    RenewPwdComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ClarityModule,
    ClrAddonsModule,
    AppRoutingModule,
    AssessmentOfflineModule,
    LayoutModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EffectsModule.forRoot([]),
    CodeInputModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ToastrModule.forRoot(),
    InfoModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RouteManagerInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(commonStrings: ClrCommonStringsService) {
    commonStrings.localize(germanLocale);
  }
}
