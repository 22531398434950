import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClarityModule } from '@clr/angular';
import { ClrAddonsModule } from '@porscheinformatik/clr-addons';
import { ClrBreadcrumbService } from '@porscheinformatik/clr-addons';

import { TelecontrolsMessagesGridCardComponent } from '../telecontrols-messages-grid-card/telecontrols-messages-grid-card.component';
import { selectTelecontrols } from '../+store/telecontrol.selectors';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ClarityModule,
    ClrAddonsModule,
    TelecontrolsMessagesGridCardComponent,
  ],
  selector: 'app-telecontrols-messages',
  templateUrl: './telecontrols-messages.component.html',
  styleUrls: ['./telecontrols-messages.component.css'],
})
export class TelecontrolsMessagesComponent implements OnInit {
  combinedTelecontrols$ = this.store.select(selectTelecontrols);

  constructor(
    private store: Store,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.updateBreadcrumb([
      { label: 'Home', url: '/' },
      { label: 'Fernwirktechnik' },
      { label: 'Meldungen' },
    ]);
  }
}
